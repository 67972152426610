<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { authorizedApiRequest } from '@/services/ApiService'
import { format } from 'date-fns'
import FileSaver from 'file-saver'

const app = getCurrentInstance()
const route = useRoute()
const isLoading = ref(true)
const downloadingId = ref(null)
const rows = ref()

const headers = computed(() => [
  {
    text: app.proxy.$t('general.title'),
    align: 'start',
    sortable: true,
    value: 'title',
    width: '60%',
  },
  {
    text: app.proxy.$t('general.actions'),
    align: 'end',
    value: 'actions',
    sortable: false,
    width: '20%',
  },
])

async function downloadCsv(item) {
  try {
    downloadingId.value = item.id

    const filename = `${item.title}_${format(new Date(), 'yyyy-MM-dd')}.csv`
    const response = await authorizedApiRequest({
      method: 'GET',
      endpoint: `api/v1/contracts/template-values/?workspace_id=${route.params.workspace_id}&template_version_id=${item.id}`,
    })

    FileSaver.saveAs(new Blob([response], { type: 'text/csv;charset=utf-8' }), filename)
  } catch (error) {
    app.proxy.$notification.error(app.proxy.$t('general.error'))
  } finally {
    downloadingId.value = null
  }
}

onMounted(async () => {
  isLoading.value = true

  try {
    const response = await authorizedApiRequest({
      method: 'GET',
      endpoint: `api/v1/contracts/templates?workspace_id=${route.params.workspace_id}`,
    })

    rows.value = [...response]
  } catch (e) {
    console.warn('on mounted', e)
  } finally {
    isLoading.value = false
  }
})
</script>

<template>
  <Data :loader="isLoading" :isDashboard="true" showMenu value>
    <v-data-table
      :items="rows"
      :headers="headers"
      :no-data-text="$t('table.noResults')"
      :no-results-text="$t('table.noResults')"
      :loading="isLoading"
    >
      <template v-slot:item.title="{ item }">
        <v-icon color="#B4C1D6" left>mdi-file-outline</v-icon>
        <span>{{ item.title }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" :loading="downloadingId === item.id" @click="downloadCsv(item)">
          {{ $t('general.download') }}
        </v-btn>
      </template>
    </v-data-table>
  </Data>
</template>

<style scoped lang="scss"></style>
